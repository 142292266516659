import { NodejsServiceRequest } from "api/NodejsRequest";

export const pushTestTemplete = (data:any) => {
    return NodejsServiceRequest.post(`/push/templetes/test`, data);
}

export const createTemplete = (data:any) => {
    return NodejsServiceRequest.post(`/push/templetes/`, data);
}

export const updateTemplete = (data:any) => {
    return NodejsServiceRequest.put(`/push/templetes/`, data);
}

export const getAllTempletes = () => {
    return NodejsServiceRequest.get(`/push/templetes/`);
}

export const deleteATemplete = (data:any) => {
    return NodejsServiceRequest.delete(`/push/templetes/?id=${data.id}`);
}

export const getLanguageTemples = () => {
    return NodejsServiceRequest.get(`/push/templetes/`);
}

export const createPushService = (data:any) => {
    return NodejsServiceRequest.post(`/push/push/`, data);
}

export const createPushEvents = (data:any) => {
    return NodejsServiceRequest.post(`/push/pushEvents/`, data);
}

export const changeActive = (data:any) => {
    return NodejsServiceRequest.post(`/push/pushEvents/changeActive`, data);
}

export const createPushCalendar = (data:any) => {
    return NodejsServiceRequest.post(`/push/pushCalendar/`, data);
}

export const createPushGroup = (data:any) => {
    return NodejsServiceRequest.post(`/push/pushGrouping/`, data);
}

export const addToPushGroup = (data:any) => {
    return NodejsServiceRequest.post(`/push/pushGrouping/addPush`, data);
}

export const removeFromPushGroup = (data:any) => {
    return NodejsServiceRequest.post(`/push/pushGrouping/removePush`, data);
}

export const updatePushService = (data:any) => {
    return NodejsServiceRequest.put(`/push/push/`, data);
}

export const updatePushEvents = (data:any) => {
    return NodejsServiceRequest.put(`/push/pushEvents/`, data);
}

export const updatePushCalendar = (data:any) => {
    return NodejsServiceRequest.put(`/push/pushCalendar/`, data);
}

export const updatePushGroup = (data:any) => {
    return NodejsServiceRequest.put(`/push/pushGrouping/`, data);
}

export const checkName = (data:any) => {
    return NodejsServiceRequest.get(`/push/objects/checkName?name=${data}`);
}

export const getS3Link = (name:string,type:string) => {
    return NodejsServiceRequest.get(`/push/objects/link?name=${name}&type=${type}`);
}

export const createObjInDB = (data:any) => {
    return NodejsServiceRequest.post(`/push/objects/`, data);
}

export const getAllObjects = () => {
    return NodejsServiceRequest.get(`/push/objects/`);
}

export const deleteObjectService = (data:any) => {
    return NodejsServiceRequest.delete(`/push/objects/?id=${data.id}&name=${data.name}&type=${data.type}`);
}

export const getAllPush = () => {
    return NodejsServiceRequest.get(`/push/push/dashboard`);
}

export const getAllPushCalendar = () => {
    return NodejsServiceRequest.get(`/push/pushCalendar/dashboard`);
}

export const getAllPushGrouping = () => {
    return NodejsServiceRequest.get(`/push/pushGrouping/dashboard`);
}

export const getAllPushEvents = () => {
    return NodejsServiceRequest.get(`/push/pushEvents/dashboard`);
}

export const getCreatedPush = () => {
    return NodejsServiceRequest.get(`/push/push/`);
}

export const getCreatedPushGroup = () => {
    return NodejsServiceRequest.get(`/push/pushGrouping?type=1`);
}

export const getAllCreatedPushGroup = () => {
    return NodejsServiceRequest.get(`/push/pushGrouping`);
}

export const deleteAPushEvent = (data:any) => {
    return NodejsServiceRequest.delete(`/push/pushEvents/?id=${data.id}`);
}

export const deleteAPush = (data:any) => {
    return NodejsServiceRequest.delete(`/push/push/?id=${data.id}`);
}

export const deleteAPushCalendar = (data:any) => {
    return NodejsServiceRequest.delete(`/push/pushCalendar/?id=${data.id}`);
}

export const deleteAPushGroup = (data:any) => {
    return NodejsServiceRequest.delete(`/push/pushGrouping/?id=${data.id}`);
}

export const deleteAllGroupPush = (data:any) => {
    return NodejsServiceRequest.delete(`/push/pushCalendar/`);
}

export const updatePostwVideo = (data:any) => {
    return NodejsServiceRequest.post(`/push/objects/post`, data);
}