import axios from 'axios';
import { getToken, logout } from 'data/users';
import swal from 'sweetalert';

// baseURL: 'https://seperate-queries-green.afini.xyz/v1'
// baseURL: 'https://seperate-queries-green.afinidata.com/v1'
// baseURL: 'http://127.0.0.1:6768/v1'
export const NodejsServiceRequest = axios.create({
    baseURL: 'https://seperate-queries-green.afinidata.com/v1'
});


NodejsServiceRequest.interceptors.request.use(
    config => {
        const token = getToken();
        config.headers.Authorization = token ? 'Bearer ' + token : '';
        return config;
    },
    err => {
        Promise.reject(err);
    }
);

NodejsServiceRequest.interceptors.response.use(
    response => response,
    error => {

        const { response: {status}} = error;

        /** usuario no autorizado */
        if (status === 401) {
        swal('Afinidata', 'Credenciales inválidas', 'error')
        .then( () => {
            logout();
            window.location.href = '/';
        });
        }

        /** usuario sin permisos */
        if (status === 403) {
        swal('Afinidata', 'Usuario no tiene permisos', 'error')
        .then( () => {
            window.location.href = '/admin/dashboard';
        });
        }

        return Promise.reject(error);
    }
    );