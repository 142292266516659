import { useEffect, useState } from "react";
import {
  Box,
  Button,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  FormControl,
  Grid,
  Typography,
  TextField,
} from "@mui/material";
import PushModalWA from "./pushModalWA";
import PushModalAPP from "./PushModalAPP";
import { createPushService, updatePushService } from "services/nodejs.service";

// import { createPushService } from "services/nodejs.service.ts";

interface LanguageData {
  on: boolean;
  id?: number | null;
  media: { id: number } | null;
  templete?: { id: number } | null;
  format?: number | null;
  //   channel: 'APP BRAZIL'| 'WHATSAPP BRAZIL' | 'APP BOTNAR' | 'WHATSAPP BOTNAR' | 'APP POR PROGRAMA' | 'WHATSAPP PROGRAMA' | 'AFINIAPP' | ''
}

export interface LanguageMap {
  spanish: LanguageData;
  english: LanguageData;
  portuges: LanguageData;
}

const outputMap = ["Activity", "Article", "Session", "Milestones"];

function sleep(ms: number) {
  return new Promise((resolve) => {
    setTimeout(resolve, ms);
  });
}

type Props = {
  close: () => void;
  visible: boolean;
  englishTemplete: any[];
  spanishTemplete: any[];
  portTemplete: any[];
  imageMedia: any[];
  videoMedia: any[];
  refeshPush: () => void;
  toEdit: any;
};

const languageTranslationMap: any = {
  es: "spanish",
  en: "english",
  pt: "portuges",
};

const templeteLanguaheTranslationMap: any = [
  "",
  "spanish",
  "english",
  "",
  "portuges",
];

function isEmpty(obj: any) {
  if (obj.reset) return true;
  return Object.keys(obj).length === 0;
}

function hasTrueValue(obj:any) {
    for (const key in obj) {
        if (typeof obj[key] === 'boolean' && obj[key] === true) {
            return true;
        } else if (typeof obj[key] === 'object' && obj[key] !== null) {
            if (hasTrueValue(obj[key])) {
                return true;
            }
        }
    }
    return false;
}

function checkForErrors(object:Record<string, any>,errors:Record<string, any>,key:string){
    if(object[key].on && object[key].id==null)       errors.id[key] = true
    if(object[key].on && object[key].templete==null) errors.templete[key] = true
    if(object[key].templete || object[key].templete.type || object[key].templete.type==undefined) return
    if(object[key].on && object[key].media==null)    errors.media[key] = true
}

function renderErrorMessages(errors: Record<string, any>): JSX.Element[] {
    const errorMessages: JSX.Element[] = [];
  
    const commonErrors = ["templetes", "media", "messages", "id"];
    const errorTextMap: Record<string, string> = {
      templetes: "Missing templates in",
      media: "Missing media in",
      messages: "Missing messages in",
      id: "Missing output on",
    };
    // const langDic = {
    //     es: 'spanish',
    //     en: 'english',
    //     pt: 'portuges',
    // }
    Object.entries(errors).forEach(([key, value]) => {
      if (typeof value === "boolean" && value) {
        // Direct boolean errors (e.g., name: true)
        errorMessages.push(
          <Typography key={key} variant="h6" color="black">
            Missing {key}
          </Typography>
        );
      } else if (typeof value === "object" && commonErrors.includes(key)) {
        Object.entries(value).forEach(([lang, isError]) => {
          if (isError) {
            errorMessages.push(
              <Typography key={`${key}-${lang}`} variant="h6" color="black">
                {errorTextMap[key]} {lang}
              </Typography>
            );
          }
        });
      }
    });
  
    return errorMessages;
  }
  

function SinglePushModal({
  close,
  visible,
  englishTemplete,
  spanishTemplete,
  portTemplete,
  imageMedia,
  videoMedia,
  refeshPush,
  toEdit,
}: Props) {
  const [ageReq, setAgeReq] = useState(false);
  const [minorModal, setMinorModal] = useState(false);
  const [pushName, setPushName] = useState<undefined | string>();
  const [ageMin, setAgeMin] = useState<undefined | number>();
  const [ageMax, setAgeMax] = useState<undefined | number>();
  const [output, setOutput] = useState<undefined | number>(0);
  const [channel, setChannel] = useState<undefined | number>(0);
  const [errors, setErrors] = useState<Object>({});
  const [languageMap, setLanguageMap] = useState<LanguageMap>({
    spanish: { on: false, media: null, format: 0, templete: null, id: null },
    english: { on: false, media: null, format: 0, templete: null, id: null },
    portuges: { on: false, media: null, format: 0, templete: null, id: null },
  });
  const resetLanguageMap = {
    spanish: { on: false, media: null, format: 0, templete: null, id: null },
    english: { on: false, media: null, format: 0, templete: null, id: null },
    portuges: { on: false, media: null, format: 0, templete: null, id: null },
  } as LanguageMap;
  const refreshModal = () => {
    setOutput(0);
    setChannel(0);
    setLanguageMap(resetLanguageMap);
    setAgeReq(false);
    setPushName(undefined)
    setAgeMin(undefined);
    setAgeMax(undefined);
  };
  const modifyLanguageMap = (
    lang: keyof LanguageMap,
    attr: string,
    val: any
  ) => {
    setLanguageMap((prevState) => ({
      ...prevState,
      [lang]: {
        ...prevState[lang],
        [attr]: val,
      },
    }));
  };
  useEffect(() => {
    if (!Object.keys(toEdit).length) {
      return;
    }
    if (toEdit.reset) {
      refreshModal();
      return;
    }
    if (toEdit.name) {
        setPushName(toEdit.name)
    }
    if (toEdit.age_min && toEdit.age_max) {
      setAgeReq(true);
      setAgeMax(toEdit.age_max);
      setAgeMin(toEdit.age_min);
    } else {
      setAgeReq(false);
      setAgeMax(undefined);
      setAgeMin(undefined);
    }
    setOutput(toEdit.output);
    setChannel(toEdit.channel);
    
    let tempLanguageMap: any = resetLanguageMap;
    Object.keys(toEdit.post_ids).forEach((element) => {
      tempLanguageMap[languageTranslationMap[element]] = {
        on: true,
        id: toEdit.post_ids[element],
      };
    });
    if (toEdit.channel < 100) {
      Object.keys(toEdit.messages).forEach((element) => {
        tempLanguageMap[languageTranslationMap[element]].on = true;
        tempLanguageMap[languageTranslationMap[element]].templete =
          toEdit.messages[element].id;
      });
    } else {
      const keyMap = [[], spanishTemplete, englishTemplete, [], portTemplete];
      toEdit.templete?.forEach((element: any) => {
        console.log('element', element)
        console.log('keyMap[element.lang]', keyMap[element.lang])
        let selected = keyMap[element.lang].find((el: any) => el.label == element.name);
        let object = imageMedia
          .concat(videoMedia)
          .find((el) => el.id == element.object_id);
        tempLanguageMap[templeteLanguaheTranslationMap[element.lang]].on = true;
        tempLanguageMap[templeteLanguaheTranslationMap[element.lang]].templete =
          selected;
        tempLanguageMap[templeteLanguaheTranslationMap[element.lang]].format =
          element.type?element.type:0;
        tempLanguageMap[templeteLanguaheTranslationMap[element.lang]].media =
          object;
      });
    }
    console.log("to edit", toEdit);
    setLanguageMap(tempLanguageMap);
  }, [toEdit]);
  useEffect(() => {
    if (!languageMap.english.on) {
      setLanguageMap((prevState) => ({
        ...prevState,
        ["english"]: { on: false, media: null, format: 0 },
      }));
    }
    if (!languageMap.spanish.on) {
      setLanguageMap((prevState) => ({
        ...prevState,
        ["spanish"]: { on: false, media: null, format: 0 },
      }));
    }
    if (!languageMap.portuges.on) {
      setLanguageMap((prevState) => ({
        ...prevState,
        ["portuges"]: { on: false, media: null, format: 0 },
      }));
    }
  }, [languageMap.english.on, languageMap.spanish.on, languageMap.portuges.on]);

  async function createPush() {
    let pushToSend: any = {
      channel: channel,
      output: output,
      name: pushName
    };
    let tempErrors={
        name:false,
        noMessages:false,
        noTempletes:false,
        templetes:{
            spanish:false,
            english:false,
            portuges:false
        },
        media:{
            spanish:false,
            english:false,
            portuges:false
        },
        messages:{
            spanish:false,
            english:false,
            portuges:false
        },
        id:{
            spanish:false,
            english:false,
            portuges:false
        }
    }
    if(pushName==undefined) tempErrors.name = true
    let templetes: any = {};
    const assignTemplate = (lang: string, templateData: any) => {
        templetes[lang] = {
          id: templateData.templete?.id,
          media: templateData.media?.id,
        };
      };
      
      const checkAndAssignTemplate = (lang: string, templateData: any) => {
        if (!templateData.templete) {
          setMinorModal(true);
          return false;
        }
        templetes[lang] = { id: templateData.templete };
        return true;
      };
      
      if(languageMap.spanish.on) checkForErrors(languageMap,tempErrors,"spanish")
      if(languageMap.english.on) checkForErrors(languageMap,tempErrors,"english")
      if(languageMap.portuges.on) checkForErrors(languageMap,tempErrors,"portuges")
      if (channel && channel > 99) {
        if(!languageMap.spanish.on && !languageMap.english.on && !languageMap.portuges.on) tempErrors.noTempletes=true
        if (languageMap.spanish.on) assignTemplate('es', languageMap.spanish);
        if (languageMap.english.on) assignTemplate('en', languageMap.english);
        if (languageMap.portuges.on) assignTemplate('pt', languageMap.portuges);
    } else {
        if(!languageMap.spanish.on && !languageMap.english.on && !languageMap.portuges.on) tempErrors.noMessages=true
        // if(languageMap.spanish.templete)
        if (languageMap.spanish.on && !checkAndAssignTemplate('es', languageMap.spanish)) return;
        if (languageMap.english.on && !checkAndAssignTemplate('en', languageMap.english)) return;
        if (languageMap.portuges.on && !checkAndAssignTemplate('pt', languageMap.portuges)) return;
      }
      
    
    pushToSend.templetes = JSON.stringify(templetes);
    let post_ids: any = {};
    if (languageMap.spanish.id) post_ids.es = languageMap.spanish.id;
    if (languageMap.english.id) post_ids.en = languageMap.english.id;
    if (languageMap.portuges.id) post_ids.pt = languageMap.portuges.id;
    pushToSend.post_ids = JSON.stringify(post_ids);
    if (ageReq) {
      pushToSend.age_max = "" + ageMax;
      pushToSend.age_min = "" + ageMin;
    }
    let push;
    if(hasTrueValue(tempErrors)) {
        console.log('tempErrors', tempErrors)
        setErrors(tempErrors)
        setMinorModal(true)
        return
    }
    if (isEmpty(toEdit)) {
      push = await createPushService(pushToSend);
    } else {
      pushToSend.id = toEdit.id;
      push = await updatePushService(pushToSend);
    }
    if (push.status == 201) {
      sleep(1000);
      refeshPush();
      refreshModal();
      close();
    }
  }
  console.log('languageMap', languageMap)
  return (
    <Modal open={visible}>
      <form>
        <Box sx={modalStyle}>
          <Grid container>
            <Grid
              container
              spacing={1 / 2}
              style={{ display: "flex", flexDirection: "row" }}
              p={1}
            >
              <Grid item xs={6}>
                <FormControl fullWidth>
                  <InputLabel id="output">Output</InputLabel>
                  <Select
                    labelId="output"
                    id="output"
                    label="Output"
                    value={output}
                    onChange={(v) => setOutput(v.target.value as number)}
                  >
                    {outputMap.map((el, key) => {
                      if (channel && channel > 99 && key !== 2) {
                        return null; // Skip keys other than 2 when channel > 99
                      }

                      return (
                        <MenuItem key={key} value={key}>
                          {el}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <FormControl fullWidth>
                  <InputLabel id="channel">Channel</InputLabel>
                  <Select
                    labelId="channel"
                    id="channel"
                    label="Channel"
                    value={channel}
                    onChange={(v) => {
                      setChannel(v.target.value as number);
                      if(v.target.value==100) setOutput(2)
                      setLanguageMap(resetLanguageMap);
                    }}
                  >
                    <MenuItem key={0} value={0}>
                      APP
                    </MenuItem>
                    <MenuItem key={1} value={100}>
                      WHATSAPP
                    </MenuItem>
                  </Select>
                </FormControl>
              </Grid>
                </Grid>
              <Grid item xs={12} p={1}>
                <TextField
                    label="Nombre de push"
                    value={pushName || ""}
                    onChange={(el) => setPushName(el.target.value)}
                    fullWidth
                />
            </Grid>
            {(channel as number) > 99 && (
              <PushModalWA
                ageReq={ageReq}
                setAgeReq={setAgeReq}
                languageMap={languageMap}
                modifyLanguageMap={modifyLanguageMap}
                output={output as number}
                channel={channel as number}
                spanishTemplete={spanishTemplete}
                englishTemplete={englishTemplete}
                portTemplete={portTemplete}
                ageMin={ageMin}
                setAgeMin={setAgeMin}
                ageMax={ageMax}
                setAgeMax={setAgeMax}
                imageMedia={imageMedia}
                videoMedia={videoMedia}
              />
            )}
            {(channel as number) < 99 && (
              <PushModalAPP
                ageReq={ageReq}
                setAgeReq={setAgeReq}
                languageMap={languageMap}
                modifyLanguageMap={modifyLanguageMap}
                output={output as number}
                channel={channel as number}
                ageMin={ageMin}
                setAgeMin={setAgeMin}
                ageMax={ageMax}
                setAgeMax={setAgeMax}
              />
            )}
          </Grid>
          <Grid container>
            <Grid item xs={6} p={1}>
              <Button variant="contained" fullWidth onClick={createPush}>
                {isEmpty(toEdit) ? "Agregar" : "Editar"}
              </Button>
            </Grid>
            <Grid item xs={6} p={1}>
              <Button
                variant="contained"
                fullWidth
                onClick={() => {
                  refreshModal();
                  close();
                }}
              >
                Cerrar
              </Button>
            </Grid>
          </Grid>
        </Box>
        <Modal open={minorModal}>
          <Box sx={minorStyle}>
            <Grid container flexDirection={"row"}>
              <Grid container p={1}>
                <Grid item>
                  <Typography variant="h6" color={"black"}>
                    Error saving push
                  </Typography>
                  {renderErrorMessages(errors)}
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs={4}>
                  <Button
                    variant="contained"
                    onClick={() => setMinorModal(false)}
                  >
                    Cerrar
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </Modal>
      </form>
    </Modal>
  );
}

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  bgcolor: "white",
  p: 4,
  display: "flex",
  flexDirection: "column",
  transform: "translate(-50%, -50%)",
  minWidth: "70%",
};

const minorStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  bgcolor: "white",
  p: 4,
  display: "flex",
  flexDirection: "column",
  transform: "translate(-50%, -50%)",
  // minWidth:'70%'
};

export default SinglePushModal;
